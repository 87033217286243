import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "./hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigate = useNavigate();
  const [token] = useAuth();

  useEffect(() => {
    const isPremiumUser = user?.email === "";
    if (
      location.pathname.includes("/LocalImageUpload") ||
      location.pathname.includes("/ImageSearch") ||
      location.pathname.includes("/TrainedCelebrities")
    ) {
      if (!isPremiumUser) {
        navigate("/home");
      }
    }
  }, [location]);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  });

  return <>{token ? <div>{children}</div> : null}</>;
};
