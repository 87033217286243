import axios from "../utils/axiosPrivate";

const useRefreshToken = () => {
  const refresh = async () => {
    try {
      const response = await axios.post("/authorize/refreshtoken", null, {
        params: { refresh_token: localStorage.getItem("refreshToken") },
      });
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      return response.data.access_token;
    } catch (error) {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return refresh;
};

export default useRefreshToken;
