import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Layout from "./components/Layout";
import { CircularProgress, Box } from "@mui/material";
// routes of zupaloop metrics added
// mentions endpoints of frontend website
const Watch = lazy(() => import("./pages/Watch"));
const Home = lazy(() => import("./pages/Home"));
const WatchPremium = lazy(() => import("./pages/watchPremium"));
const Login = lazy(() => import("./pages/Auth/Login"));
const SignUP = lazy(() => import("./pages/Auth/SignUp"));
const LocalUpload = lazy(() => import("./pages/ImageAI/LocalImageUpload"));
const ImageSearch = lazy(() => import("./pages/ImageAI/ImageSearch"));
const ImageTraining = lazy(() => import("./pages/ImageAI/ImageTraining"));

const TrainedCelebrities = lazy(() =>
  import("../src/pages/ImageAI/TrainedCelebrities")
);
const Settings = lazy(() => import("./pages/Home/Settings/Settings"));
const Metrics = lazy(() => import("./pages/Metrics/Metrics"));
const GraphMetrics = lazy(() => import("./pages/MetricsGraph/App"));

const Router = () => {
  return (
    <Suspense
      fallback={
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login></Login>}></Route>
          <Route path="/Graph" element={<GraphMetrics></GraphMetrics>}></Route>
          <Route path="/sign-up" element={<SignUP></SignUP>}></Route>
          <Route
            path="/premium/watch"
            element={
              <ProtectedRoute>
                <Layout>
                  <WatchPremium></WatchPremium>
                </Layout>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/metrics"
            element={
              <ProtectedRoute>
                <Layout>
                  <Metrics></Metrics>
                </Layout>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Layout>
                  <Settings></Settings>
                </Layout>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/watch/"
            element={
              <ProtectedRoute>
                <Layout>
                  <Watch></Watch>
                </Layout>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home></Home>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/LocalImageUpload"
            element={
              <ProtectedRoute>
                <LocalUpload></LocalUpload>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/ImageSearch"
            element={
              <ProtectedRoute>
                <ImageSearch></ImageSearch>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/ImageTraining"
            element={
              <ProtectedRoute>
                <ImageTraining></ImageTraining>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/TrainedCelebrities"
            element={
              <ProtectedRoute>
                <TrainedCelebrities></TrainedCelebrities>
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
