import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "./theme";
import "./App.css";
import Router from "./Router";
import Navbar from "./components/Layout/Navbar";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <Router></Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
