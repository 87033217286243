import { useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import AccountMenu from "./AccountMenu";
import ZupaLoopLogo from "../../assets/zupaLoop-logo.png";
import { useNavigate } from "react-router-dom";
import { NavbarContainer } from "./style";
import Menu from "@mui/material/Menu";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [premiumUser, setPremiumUser] = useState(false);
  const [loc, setLoc] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/");
    }
  });

  useEffect(() => {
    if (user?.email === "") {
      setPremiumUser(true);
    }
  }, [user?.email]);

  useEffect(() => {
    if (
      location.pathname == "/watch/" ||
      location.pathname == "/premium/watch/"
    ) {
      setLoc(false);
    } else {
      setLoc(true);
    }
  }, [loc]);

  return (
    <NavbarContainer>
      {/* {loc ? (
        <Button
          style={{
            paddingLeft: 40,
            color: "white",
            fontSize: 17,
            textTransform: "none",
          }}
          id="basic-button"
          aria-controls="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Image Analysis
        </Button>
      ) : null} */}

      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ textAlign: "center", marginTop: 8, marginLeft: 20 }}
      >
        {premiumUser ? (
          <div>
            <MenuItem onClick={handleClose} style={{ fontSize: 17 }}>
              <a href="./LocalImageUpload" style={{ color: "black" }}>
                Train with Your Image
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose} style={{ fontSize: 17 }}>
              <a href="./ImageSearch" style={{ color: "black" }}>
                Celeb Model Source
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose} style={{ fontSize: 17 }}>
              <a href="./ImageTraining" style={{ color: "black" }}>
                Image Analyzer
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose} style={{ fontSize: 17 }}>
              <a href="./TrainedCelebrities" style={{ color: "black" }}>
                Celebrities Trained
              </a>
            </MenuItem>
          </div>
        ) : (
          <MenuItem onClick={handleClose} style={{ fontSize: 17 }}>
            <a href="./ImageTraining" style={{ color: "black" }}>
              Image Analyzer
            </a>
          </MenuItem>
        )}
      </Menu> */}

      <Box
        className="width-lg"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Box
          style={{
            cursor: "pointer",
          }}
          height={30}
          width={150}
        >
          <img
            width="100%"
            height="100%"
            onClick={() => navigate("/home")}
            src={ZupaLoopLogo}
          ></img>
        </Box>
        <AccountMenu />
      </Box>
    </NavbarContainer>
  );
};

export default Navbar;
