import { styled, Box } from "@mui/material";

export const FooterContainer = styled(Box)(() => ({
  backgroundColor: "#25282f",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  height: "50px",
  width: "100%",
  position: "absolute",
}));

export const NavbarContainer = styled(Box)(() => ({
  // width: "100%",
  backgroundColor: "#25282f",
  display: "flex",
  alignItems: "center",
  height: "50px",
}));
