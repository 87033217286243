import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

export const useAuth = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("accessToken");

    if (!tokenString) {
      localStorage.clear();
      return null; // check valid token
    }
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  if (token) {
    const decoded = jwtDecode(token);
    localStorage.setItem("user", JSON.stringify(decoded));
  }

  const saveToken = (userToken: string) => {
    localStorage.setItem("accessToken", userToken);
    setToken(userToken);
  };

  return [token, saveToken];
};

export default useAuth;
