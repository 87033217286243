import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 426,
      sm: 769,
      md: 1025,
      lg: 1441,
      xl: 2160,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      //@ts-ignore
      navBar: "#fff",
      light: "#E0E0E0",
    },
    primary: {
      main: "#6248C0",
    },
    secondary: {
      main: "#000",
      light: "#00838fc4",
      dark: "#00838fc4",
      contrastText: "#fff",
    },
    text: {
      primary: "#2B2C2D",
      secondary: "#5C5C5C",
      //@ts-ignore
      blue: "#2F80ED",
      purple: "#6248C0",
      error: "#EB5757",
    },
  },

  mixins: {
    toolbar: {
      minHeight: "inherit",
    },
  },

  typography: {
    htmlFontSize: 16,
    //@ts-ignore
    pxToRem: (size) => `${size / 16}rem`,
    pxToVw: (size: number) => `${size / 14.4}vw`,
    fontFamily: "'Poppins', 'Roboto', sans-serif",
    boxSizing: "border-box",
    h1: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "2.125rem", // 34
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.625rem", // 26
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.5rem", // 24
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Poppins, 'Roboto', sans-serif",
      fontSize: "1.375rem", // 22
    },
    h5: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.25rem", // 20
    },
    h6: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.125rem", // 18
    },
    subtitle1: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.125rem",
      fontWeight: 600,
      color: "#2B2C2D",
    },
    subtitle2: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#2B2C2D",
    },
    subtitle3: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#2B2C2D",
    },
    body1: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "#535353",
    },
    body2: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#6C6C6C",
    },
    body3: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#2B2C2D",
    },
    body4: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#2B2C2D",
    },
    body5: {
      fontFamily: "'Poppins', 'Roboto', sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#5C5C5C",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // change the backround for the contained mui button
          // "&.MuiButton-contained": {
          //   backgroundColor: "#6248C0",
          //   color: "#fff",
          //   fontWeight: 600,
          // },
          //change the color of the disabled button
          // color: "#fff",
          // fontWeight: 600,
          // textTransform: "none",
          // background: "#6248C0",
          // "&.Mui-disabled": {
          //   color: "#fff",
          // },
          // "&:hover": {
          //   backgroundColor: "#6248C0",
          // },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.935rem",
          "& ::placeholder": {
            fontSize: "0.835rem",
          },
        },
        // change the border radius of mui text field
      },
    },
    //mui table container scroll bar style
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            height: 7,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#00838F",
          },
        },
      },
    },
  },
});
